import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";

import { useFormattedMessage } from "hooks";
import NewFormTermsCondition from "components/FormTermsCondition/newFormTermsCondition";
import { email } from "i18n/messages/formElements";
import { sendFormError } from "i18n/messages/errors";
import { subscribeProjectNewsletter } from "api";
import Input from "components/Input";
import { getUserEmail } from "utils";
import NewLoader from "components/NewLoader";

const FINX_COOKIE = require("common/constants");

const messages = {
    productSubscriptionHeadingFirst: "product_subscription_heading_first",
    productSubscribedSubHeadingSecond: "product_subscription_heading_second",
    requifredField: "required_field",
    invalidEmail: "invalid_email",
    toConfirmText: "confirm_tile_button",
    sendFormError,
    alreadySubscribed: "already_subscribed",
    ...email,
};
const SubscribeProduct = ({ setSubscriptionStep, productName, projectID, setSubscribed, isLoading = false }) => {
    const { formatMessage } = useFormattedMessage();
    const { values, errors, touched, isSubmitting, handleBlur, handleSubmit, handleChange } = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: getUserEmail() || "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("requiredField").email("invalidEmail"),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                setSubmitting(true);
                const data = {
                    email: values.email,
                    projectID: projectID,
                    tags: [{ Tag: projectID, StatusTag: "active" }],
                };
                const res = await subscribeProjectNewsletter(data);

                if (res.data && res.data.errors && res.data.errors[0]) {
                    if (res.data.errors[0].message == "already_subscribed") {
                        setErrors({ requestError: "alreadySubscribed" });
                    } else {
                        setErrors({ requestError: "send_form_error" });
                    }
                } else {
                    Cookies.set(FINX_COOKIE.USER_EMAIL, values.email, { expires: 1 / 48 }); // cookie will be expire in 30min
                    setSubscribed(true);
                    setSubscriptionStep("subscribed");
                }
                setSubmitting(false);
            } catch (error) {
                setErrors({ requestError: "send_form_error" });
                setSubmitting(false);
                console.error(error, "error");
            }
        },
    });

    return (
        <div className="content-part">
            <div className="product-subscribed">
                <div className="icon-section">
                    <i className="far fa-bell" />
                </div>
                <h4 className="heading">
                    {formatMessage(messages.productSubscriptionHeadingFirst)} {productName}
                    {formatMessage(messages.productSubscribedSubHeadingSecond)}
                </h4>
                <form method="post" onSubmit={handleSubmit} className="mt-9">
                    <Input
                        id="email"
                        className="mb-5"
                        inputClassName="text-input form-item"
                        name="email"
                        type="text"
                        value={values.email}
                        placeholder={formatMessage(messages.emailPlaceholder)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={errors.email ? formatMessage(messages[errors.email]) : null}
                        error={touched.email && errors.email}
                    />

                    {errors.requestError && (
                        <span className="error d-block w-100 pl-0 ">{formatMessage(messages[errors.requestError])}</span>
                    )}
                    <button
                        className={`mw-100 my-0 ${isSubmitting ? "disable-grey" : ""}`}
                        type="submit"
                        disabled={Object.keys(errors).length}
                    >
                        {isLoading || isSubmitting ? (
                            <>
                                <NewLoader />
                                {formatMessage(messages.toConfirmText)}
                            </>
                        ) : (
                            formatMessage(messages.toConfirmText)
                        )}
                    </button>
                    <div className="p-2 pt-5">
                        <NewFormTermsCondition />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SubscribeProduct;
