import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Head from "next/head";
import { useRouter } from "next/router";
import SingletonApi from "api/SingletonApi";
import { getProjectOffers, getPerformances, fetchProjectTokens, fetchProductCatalogProjectsLimited } from "api";
import { useFormattedMessage } from "hooks";
import {
    USER_ROLES,
    PERFORMANCE_TYPE_CONSTANT,
    getProjectTypeTranslatedLabels,
    TENANTS_LIST,
    typeInvestmentProductValues,
} from "constants/index";
import { PROJECT_STATUS } from "constants/status";
import { addOverlay, sideModalScrollHidden, removeOverlay } from "utils";
import { propertyPage } from "i18n/messages/meta";
import PropertyItem from "components/PropertyItem";
import InfoBlock from "components/InfoBlock";
import SelectInput from "components/SelectInput";
import PropertyInterestModal from "components/PropertyInterestModal";
import WrappedProductSubscription from "components/Subscribed/WrappedProductSubscription";
import PropertyFilterIcon from "assets/images/svg/property-filter.svg";
import {
    selectMarketplaceProjectCategorized,
    selectMarketplaceProject,
    selectProjectButton,
    selectProjectTypes,
    selectProjectStatus,
} from "store/project/selectors";
import { selectEmail, selectUserRoles, selectIsLoggedIn } from "store/user/account/selectors";
import {
    selectCurrentTenant,
    selectIsCurrentTenantFinexity,
    selectPlatformSourceFromApp,
    selectTenant,
    selectLocale,
} from "store/app/selectors";
import { fetchProjectLimitedStart, fetchProjectLimitedSuccess } from "store/project/actions";
import { setUserSubscriptionSuccess } from "store/user/account/actions";
import { PROJECT_SELECTOR_LIMITED } from "common/constants";
import { getTenantFromHost, getBaseApiURL } from "common/helper";
import NavLink from "components/NavLink";
import { winvestaPrivatePlacement } from "constants/general_constants";

const FINX_COOKIE = require("common/constants");

let dataType, dataStatus;
const lastMonthDate = new Date().setDate(new Date().getDate() - 30);
const formatedLastMonthDate = moment(new Date(lastMonthDate)).format("YYYY-MM-DD");
const todayDate = moment(new Date()).format("YYYY-MM-DD");

const PROJECT_STATUS_FILTER_VALUES = {
    ALL_PROJECTS: "All Projects",
    FUNDING_ACTIVE: "Funding Active",
    VIP_FUNDING: "VIP funding",
    MARKETPLACE: "Marketplace",
};

const unitValue = { k: 1000 };
const defaultInvestmentShare = 500;

const messages = {
    heading: "marketplace",
    investmentFromHeading: "marketplace_investment_from_heading",
    InfoBlockHeading: "how_it_works",
    InfoBlockText: "marketplace_info_block_text",
    InfoBlockLinkText: "learn_more",
    marketplaceBottomNote: "past_performance",
    allProducts: "all_products",
    fundingActive: "funding_active",
    tradingActive: "trading_active",
    vacationProperty: "vacation_property",
    requestAccessText: "request_access_now",
    clubdealsProfitFirstText: "marketplace_clubdeals_profit_first_text",
    clubdealsProfitSecondText: "marketplace_clubdeals_profit_second_text",
    clubdealsProfitThirdText: "marketplace_clubdeals_profit_third_text",
    clubdealsButtonText: "apply_now",
    filter: "filter_mgmt",
    winvestaHowItWorkTitle: "winvesta_exclusive_private_placement",
    winvestaHowItWorkSubTitle: "winvesta_exclusive_opportunities_way",
    winvestaHowItWorkInvestmentStart: "winvesta_investment_start_amount",
    winvestaHowItWorkContent: "winvesta_how_it_works_content",
    winvestaHowItWorkButton: "winvesta_invest_now",
};

const PropertyPage = ({
    fetchProjectLimitedStart,
    categorizedProducts = {},
    allProducts = [],
    isLoggedIn,
    userRoles,
    propertyButton,
    allProductTypes,
    projectStatus,
    isApp,
    isCurrentTenantFinexity,
    setUserSubscriptionSuccess,
    currentTenant,
    locale,
}) => {
    const router = useRouter();
    const inputRef = useRef(null);
    const element = useRef(null);
    const sizer = useRef(null);
    const { formatMessage } = useFormattedMessage();

    const PROJECT_TYPE_TRANSLATED_LABELS = getProjectTypeTranslatedLabels();

    const productStatusFilterOptions = [
        {
            value: PROJECT_STATUS_FILTER_VALUES.ALL_PROJECTS,
            label: formatMessage(messages.allProducts),
            searchLabel: formatMessage(messages.allProducts),
            status: "all",
        },
        {
            value: [PROJECT_STATUS_FILTER_VALUES.FUNDING_ACTIVE, PROJECT_STATUS_FILTER_VALUES.VIP_FUNDING],
            label: formatMessage(messages.fundingActive),
            searchLabel: formatMessage(messages.fundingActive),
            status: "active",
        },
        {
            value: [PROJECT_STATUS_FILTER_VALUES.MARKETPLACE],
            label: formatMessage(messages.tradingActive),
            searchLabel: formatMessage(messages.tradingActive),
            status: "trading",
        },
    ];

    const [isMobileScreen, setMobileScreen] = useState(false);
    const [isOpenFilterMenu, setIsOpenFilterMenu] = useState(false);
    const [propertyInterestModalFlag, setPropertyInterestModalFlag] = useState(false);
    const [subscribedModalOpen, setSubscribedModalOpen] = useState(false);
    const [projectTokens, setProjectTokens] = useState([]);
    const [projectOffers, setProjectOffers] = useState([]);
    const [performances, setPerformances] = useState([]);
    const [selectedProductStatus, setSelectedProductStatus] = useState(productStatusFilterOptions[0]);
    const [selectedProductType, setSelectedProductType] = useState("");
    const [scrollPosition, setScrollPosition] = useState(0);
    const [project, setProject] = useState({});
    const [subscriptionStep, setSubscriptionStep] = useState("subscribe");
    const [propertyButtons, setPropertyButtons] = useState(
        currentTenant?.TechnicalName === TENANTS_LIST.GOLDENCIRCLE ? allProductTypes : propertyButton,
    );
    const VIPUser = userRoles?.length && !!userRoles.find((role) => role === USER_ROLES.VIP);
    const renderProducts =
        currentTenant?.TechnicalName === TENANTS_LIST.GOLDENCIRCLE ? allProducts : categorizedProducts?.properties;
    const infoBoxHeight = `${sizer?.current?.clientHeight ?? 450}px`;
    const headingClassName = isApp ? "mt-0 pt-4" : "mt-6";

    const type = project?.type?.type;
    const typeClubShareNumber = type?.split("-")?.[2]?.trim()?.match(/\d+/g);
    const typeClubShareUnit = type
        ?.split("-")?.[2]
        ?.trim()
        ?.match(/[a-zA-Z]+/g);
    const typeClubShare =
        typeClubShareNumber && typeClubShareUnit && parseInt(typeClubShareNumber) * unitValue[typeClubShareUnit];
    const minInvestmentShare =
        (project?.typeInvestmentProduct && typeInvestmentProductValues[project?.typeInvestmentProduct]?.minAmount) ||
        typeClubShare ||
        defaultInvestmentShare;

    const filterProductsStatusAndTypeBased = (element) => {
        if (element.getAttribute("data-type") == dataType && element.getAttribute("data-status") == dataStatus) {
            return element;
        } else {
            return (
                element.getAttribute("data-type") == selectedProductType &&
                selectedProductStatus?.value?.includes(element.getAttribute("data-status"))
            );
        }
    };

    const filterProductsTypeBased = (element) => {
        if (element.getAttribute("data-type") == dataType || element.getAttribute("data-status") == dataStatus) {
            return element;
        } else {
            return (
                element.getAttribute("data-type") == selectedProductType ||
                selectedProductStatus?.value?.includes(element.getAttribute("data-status"))
            );
        }
    };

    const filterProducts = () => {
        var propertyItems = document.querySelectorAll("a.property-item");

        if (!propertyItems) {
            return;
        }
        if (selectedProductStatus?.value === PROJECT_STATUS_FILTER_VALUES.ALL_PROJECTS && selectedProductType == "") {
            jQuery("a.property-item").removeClass("d-none");
        } else {
            jQuery("a.property-item").addClass("d-none");
            if (selectedProductStatus?.value !== PROJECT_STATUS_FILTER_VALUES.ALL_PROJECTS && selectedProductType) {
                const filteredItems = Array.prototype.filter.call(propertyItems, filterProductsStatusAndTypeBased);
                filteredItems?.forEach((e) => jQuery(e).removeClass("d-none"));
            } else {
                const filteredItems = Array.prototype.filter.call(propertyItems, filterProductsTypeBased);
                filteredItems?.forEach((e) => jQuery(e).removeClass("d-none"));
            }
        }
    };

    const fetchPerformances = (variables) => {
        try {
            getPerformances(variables)
                .then((res) => {
                    res?.data?.data?.performances?.performances?.length &&
                        setPerformances(res.data.data.performances.performances);
                })
                .catch(() => {});
        } catch (error) {
            /* error */
        }
    };

    const getProjectTokens = (variables) => {
        try {
            fetchProjectTokens(variables)
                .then((res) => {
                    res?.data?.data?.projectTokens?.projectTokens?.length &&
                        setProjectTokens(res.data.data.projectTokens.projectTokens);
                })
                .catch(() => {});
        } catch (error) {
            /* error */
        }
    };

    const fetchProjectOffers = async () => {
        try {
            const response = await getProjectOffers();
            response && setProjectOffers(response?.data?.data?.projectOffers?.projectOffers || []);
        } catch (error) {
            /* error */
        }
    };

    useEffect(() => {
        let allPropertyButton = currentTenant?.TechnicalName === TENANTS_LIST.GOLDENCIRCLE ? allProductTypes : propertyButton;
        if (currentTenant?.TechnicalName === TENANTS_LIST.FINEXITY_HEALTHCARE) {
            !router?.query?.type && pushURL("type", "Healthcare");
            // !router?.query?.status && pushURL("status", "active"); // Commented for now due to funding active products not available.
            allPropertyButton = ["Healthcare", ...allPropertyButton.filter((item) => item !== "Healthcare")];
        }
        setPropertyButtons(allPropertyButton);

        // Set product type and status to filter
        dataType = "[";
        dataType += allPropertyButton.toString() + "]";

        dataStatus = "[";
        dataStatus += projectStatus.toString() + "]";
    }, [currentTenant, allProductTypes]);

    useEffect(() => {
        // Set mobile screen
        if (window.outerWidth < 992) {
            setMobileScreen(true);
        }

        // Fetch latest performance data last 30 days
        fetchPerformances({
            fromValuationDate: formatedLastMonthDate,
            toValuationDate: todayDate,
            typePerformance: PERFORMANCE_TYPE_CONSTANT.TMP,
        });

        // Fetch latest project tokens data
        getProjectTokens({
            projectStatus: `${PROJECT_STATUS.MARKETPLACE_PAUSED}, ${PROJECT_STATUS.MARKETPLACE}`,
        });
    }, []);

    useEffect(() => {
        fetchProjectLimitedStart();
        fetchProjectOffers();

        const intervalId = setInterval(() => {
            fetchProjectLimitedStart();
        }, 1800000); // 1800000 = 30 minutes

        return () => clearInterval(intervalId);
    }, []);

    useMemo(() => {
        setSelectedProductType(router?.query?.type || "");
    }, [router?.query?.type]);

    useEffect(() => {
        router?.query?.status
            ? setSelectedProductStatus(productStatusFilterOptions.find((x) => x.status === router?.query?.status) || "")
            : setSelectedProductStatus(productStatusFilterOptions[0]);
    }, [router?.query?.status, locale]);

    // Filter products on type and status change
    useEffect(() => {
        filterProducts();
    }, [selectedProductStatus, selectedProductType, renderProducts]);

    // Handle scroll event for desktop and mobile screens
    useEffect(() => {
        if (isApp || isMobileScreen) {
            $(".main-app-container").unbind("scroll").scroll(handleMobileScroll);
        } else {
            window.addEventListener("scroll", handleScroll, { passive: true });
        }
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Scroll page to a particular position with query parameter "scrollPosition"
    useEffect(() => {
        if (
            router?.query?.scrollPosition &&
            parseFloat(router?.query?.scrollPosition) &&
            !isNaN(parseFloat(router?.query?.scrollPosition))
        ) {
            jQuery(isApp ? ".main-app-container" : "body, html").animate(
                {
                    scrollTop: router?.query?.scrollPosition,
                },
                600,
            );
        }
    }, [router?.query?.scrollPosition]);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const handleMobileScroll = () => {
        const position = jQuery(".main-app-container").scrollTop();
        setScrollPosition(position);
    };

    // single data filter
    const singleProjectOffers = (projectID) =>
        projectOffers?.length && projectOffers.find((item) => item.projectID === projectID);
    const singleProjectTMPPerformances = (projectID) =>
        performances?.length && performances.filter((item) => item.LinkProject === projectID);
    const singleProjectTokens = (projectID) =>
        projectTokens?.length && projectTokens.find((item) => item.projectID === projectID);

    const onFocusStatusHiddenInput = () => {
        if (isOpenFilterMenu) {
            setIsOpenFilterMenu(false);
        } else {
            setIsOpenFilterMenu(true);
            inputRef.current.focus();
        }
    };

    const pushURL = (type, value, isRemoveFromQuery = false) => {
        if (isRemoveFromQuery) {
            delete router.query[type];
        } else {
            router.query[type] = value;
        }
        router.push(router, undefined, { shallow: true });
    };

    const onChangeType = (value) => {
        if (value == selectedProductType) {
            setSelectedProductType("");
            pushURL("type", value, true);
        } else {
            pushURL("type", value);
        }
    };

    const onChangeStatus = (value) => {
        setIsOpenFilterMenu(false);
        pushURL("status", value.status);
    };

    const onPropertyClick = (_e, _project, _loggedIn) => {
        if (_project.status && _project.status.status === PROJECT_STATUS.COMING_SOON) {
            _e.preventDefault();
            setProject(_project);
            addOverlay();
            sideModalScrollHidden(true);
            if (_loggedIn) {
                setPropertyInterestModalFlag(true);
                return true;
            }
            setSubscribedModalOpen(true);
            setSubscriptionStep("subscribe");
        }
    };

    return (
        <>
            <Head>
                <title>{formatMessage(propertyPage.title)}</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="description" content={formatMessage(propertyPage.description)} />
            </Head>

            {isLoggedIn ? (
                <PropertyInterestModal
                    minShareTypeWise={minInvestmentShare}
                    hideReserveSection={!isLoggedIn}
                    open={propertyInterestModalFlag}
                    projectID={project.projectID}
                    title={project.title}
                    numberOfShares={project?.financials?.numberOfShares}
                    onCloseButtonClick={() => {
                        removeOverlay();
                        sideModalScrollHidden(false);
                        setPropertyInterestModalFlag(false);
                    }}
                />
            ) : (
                <WrappedProductSubscription
                    modalOpen={subscribedModalOpen}
                    productName={project.title}
                    isLoggedIn={isLoggedIn}
                    isSubscribed={project.isSubscribed}
                    subscriptionStep={subscriptionStep}
                    setSubscriptionStep={setSubscriptionStep}
                    setSubscribed={() => {}}
                    closeModal={() => {
                        removeOverlay();
                        sideModalScrollHidden(false);
                        setSubscribedModalOpen(false);
                    }}
                    projectID={project.projectID}
                    setUserSubscriptionSuccess={setUserSubscriptionSuccess}
                />
            )}

            <div
                className={`marketplace-page container property broad-container ${
                    isLoggedIn ? "private-marketplace-page" : "public-marketplace-page"
                }`}
            >
                <section className="property-section small-container">
                    <h2 className={headingClassName}>{formatMessage(messages.heading)}</h2>
                    <div className={"d-flex flex-wrap"}>
                        <div className="default-projects w-100">
                            <div className="flex justify-between align-center head-title mb-6 ">
                                <h3 className="mt-0 mb-0">{formatMessage(messages.investmentFromHeading)}</h3>
                                {renderProducts?.length > 0 && (
                                    <div className="react-select-with-input">
                                        {/* Doing this for proper working of the select dropdown in all places including webview */}
                                        <div className="react-select__hidden-input">
                                            <input type="text" onClick={onFocusStatusHiddenInput} />
                                        </div>
                                        <SelectInput
                                            innerRef={inputRef}
                                            menuIsOpen={isOpenFilterMenu}
                                            onBlur={() => setIsOpenFilterMenu(false)}
                                            placeholder="Please select"
                                            className="property-select-filter mb-0"
                                            label={
                                                <>
                                                    <PropertyFilterIcon style={{ height: 18, width: 18, paddingTop: "4px" }} />{" "}
                                                    {formatMessage(messages.filter)}
                                                </>
                                            }
                                            isSearchable={false}
                                            name="projects"
                                            options={productStatusFilterOptions}
                                            value={selectedProductStatus}
                                            onChange={(value) => onChangeStatus(value)}
                                            hideSelectedOptions={false}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="flex project-btn-container">
                                {propertyButtons.map((property, projectIndex) => {
                                    return (
                                        <button
                                            key={projectIndex}
                                            className={`mw-fit mx-2 filter-project-btn ${
                                                selectedProductType == property && "selected-project"
                                            }`}
                                            onClick={() => onChangeType(property)}
                                        >
                                            {PROJECT_TYPE_TRANSLATED_LABELS[property]}
                                        </button>
                                    );
                                })}
                            </div>
                            <div className="container-row">
                                <div ref={element} className="property-block pb-10">
                                    {renderProducts?.map?.((project, projectIndex) => {
                                        const teaserImage = project?.images?.teaserImage
                                            ? `${project.images.teaserImage.path}?w=360`
                                            : null;
                                        const modifiedType =
                                            project?.type?.type &&
                                            `${project?.type?.type?.split("-")?.[0]}${
                                                project?.type?.type?.split("-")?.[1]
                                                    ? "-" + project?.type?.type?.split("-")?.[1]
                                                    : ""
                                            }`.trim();
                                        const combineAddress =
                                            project?.address && `${project?.address.city}, ${project?.address.country}`;
                                        const price = 1;
                                        const projectOffers = singleProjectOffers(project?.projectID);
                                        const projectPerformances = singleProjectTMPPerformances(project?.projectID);
                                        const projectTokens = singleProjectTokens(project?.projectID);

                                        // Total Average of last thirty Days Performances TokenPrice
                                        const averageTotalTokenPrice =
                                            Array.isArray(projectPerformances) &&
                                            projectPerformances.length &&
                                            projectPerformances.reduce((total, obj) => parseFloat(obj.TokenPrice) + total, 0) /
                                                projectPerformances.length;
                                        // caret of Total Average data
                                        const caretAverageTokenPrice =
                                            Array.isArray(projectPerformances) &&
                                            projectPerformances.length > 0 &&
                                            projectPerformances.map((item) => {
                                                const averageTMP = item.TokenPrice - averageTotalTokenPrice;
                                                let caretAverageTMP = Math.pow(averageTMP, 2);
                                                return caretAverageTMP++;
                                            });
                                        // sum of caret Average data
                                        const sumCaretAverageTokenPrice =
                                            Array.isArray(caretAverageTokenPrice) &&
                                            caretAverageTokenPrice.length &&
                                            caretAverageTokenPrice.reduce((total, x) => parseFloat(x) + total, 0);
                                        // Daily Standard Deviation
                                        const dailyStandardDeviation = parseFloat(
                                            Math.sqrt(sumCaretAverageTokenPrice / 30),
                                        ).toFixed(2);
                                        // monthly Standard Deviation
                                        const monthlyStandardDeviation = (dailyStandardDeviation * Math.sqrt(365 / 12)).toFixed(
                                            2,
                                        );
                                        // monthly Token Market Price Volatility
                                        const monthlyTMPVolatility = (monthlyStandardDeviation / averageTotalTokenPrice) * 100;

                                        return (
                                            <PropertyItem
                                                reference={sizer}
                                                projectID={project?.projectID}
                                                VIPUser={VIPUser}
                                                key={projectIndex}
                                                href={`/marketplace/${project?.projectID}?status=${selectedProductStatus?.status}&type=${selectedProductType}&scrollPosition=${scrollPosition}`}
                                                image={teaserImage}
                                                propertyType={modifiedType}
                                                title={project?.title}
                                                location={combineAddress}
                                                shares={project?.financials?.numberOfShares}
                                                returnEstimate={project?.financials?.totalReturnEstimate}
                                                status={project?.status?.status}
                                                price={price}
                                                profit={project?.financials?.numberOfShares * price}
                                                fundingPercentage={project?.fundingPercentage}
                                                className="photo-item"
                                                buyCheapestPrice={projectOffers?.sellPrice}
                                                sellCheapestPrice={projectOffers?.buyPrice}
                                                sellHighestPrice={projectOffers?.highestBuyPrice}
                                                monthlyTMPVolatility={monthlyTMPVolatility}
                                                TMP={parseFloat(projectTokens?.tokenMarketPrice)}
                                                ITV={parseFloat(projectTokens?.indicativeTokenValue)}
                                                isVideo={!!project?.images?.teaserVideo?.path}
                                                videoPath={project?.images?.teaserVideo?.path}
                                                actionBox={project.actionBox}
                                                onClick={(e) => {
                                                    onPropertyClick(e, project, isLoggedIn);
                                                }}
                                            />
                                        );
                                    }) || null}
                                    {currentTenant?.TechnicalName === TENANTS_LIST.WINVESTA ? (
                                        <div
                                            className="flex column item-block property-item photo-item"
                                            style={{ cursor: "default", height: infoBoxHeight }}
                                            data-type={dataType}
                                            data-status={dataStatus}
                                        >
                                            <div
                                                className={`flex column justify-between info-block white-color private-placement-tile`}
                                            >
                                                <div className="flex column">
                                                    <div className="info-item fw-700 text-22 mb-4">
                                                        {formatMessage(messages.winvestaHowItWorkTitle)}
                                                    </div>
                                                    <span className="text-12 mb-1">
                                                        {formatMessage(messages.winvestaHowItWorkSubTitle)}
                                                    </span>
                                                    <span className="text-14 mb-8 fw-700 highlight-text">
                                                        {formatMessage(messages.winvestaHowItWorkInvestmentStart)}
                                                    </span>
                                                    <span className="text-12 mb-14">
                                                        {formatMessage(messages.winvestaHowItWorkContent)}
                                                    </span>
                                                </div>
                                                <div>
                                                    <NavLink href={winvestaPrivatePlacement} isExternal>
                                                        <button className="mb-0 fw-700 text-18" tabIndex={0}>
                                                            {formatMessage(messages.winvestaHowItWorkButton)}
                                                        </button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {isCurrentTenantFinexity || currentTenant?.HowItWorkTileContent?.ReadMoreEnable ? (
                                        <div
                                            className="flex column item-block property-item photo-item"
                                            style={{ cursor: "default", height: infoBoxHeight }}
                                            data-type={dataType}
                                            data-status={dataStatus}
                                        >
                                            <InfoBlock
                                                isWebFlowLink
                                                title={
                                                    isCurrentTenantFinexity
                                                        ? currentTenant?.HowItWorkTileContent?.ReadMoreEnable
                                                            ? locale == "en"
                                                                ? currentTenant?.HowItWorkTileContent?.TitleEN
                                                                : currentTenant?.HowItWorkTileContent?.TitleDE
                                                            : formatMessage(messages.InfoBlockHeading)
                                                        : locale == "en"
                                                          ? currentTenant?.HowItWorkTileContent?.TitleEN
                                                          : currentTenant?.HowItWorkTileContent?.TitleDE
                                                }
                                                paragraphs={[
                                                    isCurrentTenantFinexity
                                                        ? currentTenant?.HowItWorkTileContent?.ReadMoreEnable
                                                            ? locale == "en"
                                                                ? currentTenant?.HowItWorkTileContent?.DescriptionEN
                                                                : currentTenant?.HowItWorkTileContent?.DescriptionDE
                                                            : formatMessage(messages.InfoBlockText)
                                                        : locale == "en"
                                                          ? currentTenant?.HowItWorkTileContent?.DescriptionEN
                                                          : currentTenant?.HowItWorkTileContent?.DescriptionDE,
                                                ]}
                                                linkText={formatMessage(messages.InfoBlockLinkText)}
                                                linkHref={
                                                    isCurrentTenantFinexity
                                                        ? currentTenant?.HowItWorkTileContent?.ReadMoreEnable
                                                            ? currentTenant?.HowItWorkTileContent?.ReadMoreURL
                                                            : "/wealth/what-is-digital-wealth"
                                                        : currentTenant?.HowItWorkTileContent?.ReadMoreURL
                                                }
                                                isLinkResetFilter={
                                                    currentTenant?.TechnicalName === TENANTS_LIST.FINEXITY_HEALTHCARE
                                                }
                                                onResetFilter={() => {
                                                    onChangeType(selectedProductType);
                                                    onChangeStatus(productStatusFilterOptions[0]);
                                                }}
                                                color="rgb(241,245,245)"
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="pb-10">
                            <p className="body-medium">{formatMessage(messages.marketplaceBottomNote)}</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

PropertyPage.getInitialProps = async ({ store: { dispatch }, isServer, req }) => {
    if (isServer) {
        const customWindow = { location: { host: req?.hostname } };
        const baseApiUrl = getBaseApiURL(customWindow);
        const currentTenant = getTenantFromHost(req?.hostname);
        const userEmail = req.cookies[FINX_COOKIE.USER_EMAIL];

        SingletonApi.setBaseURL(baseApiUrl);
        SingletonApi.setHeader("finexity-tenant", currentTenant);

        const response = await fetchProductCatalogProjectsLimited(10, "", userEmail);
        const projects = response?.data?.data?.productCatalogProjects?.projects;
        projects && dispatch(fetchProjectLimitedSuccess(projects));
    }
    return { isServer };
};

const mapStateToProps = (state) => ({
    propertyButton: selectProjectButton(state, PROJECT_SELECTOR_LIMITED),
    allProductTypes: selectProjectTypes(state, PROJECT_SELECTOR_LIMITED),
    projectStatus: selectProjectStatus(state, PROJECT_SELECTOR_LIMITED),
    categorizedProducts: selectMarketplaceProjectCategorized(state, PROJECT_SELECTOR_LIMITED),
    allProducts: selectMarketplaceProject(state, PROJECT_SELECTOR_LIMITED),
    userEmail: selectEmail(state),
    userRoles: selectUserRoles(state),
    isLoggedIn: selectIsLoggedIn(state),
    isApp: selectPlatformSourceFromApp(state),
    currentTenant: selectCurrentTenant(state),
    isCurrentTenantFinexity: selectIsCurrentTenantFinexity(state),
    tenant: selectTenant(state),
    locale: selectLocale(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectLimitedStart: (state) => dispatch(fetchProjectLimitedStart(state)),
    setUserSubscriptionSuccess: () => dispatch(setUserSubscriptionSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyPage);
